<script lang="ts">
	import Button from "./Button.svelte";
	import { _ } from 'svelte-i18n';
</script>

<div class="why">
	<h2 class="supportUsTitle">{$_('supportUsTitle')}</h2>
	<p class="supportUsText">{$_('supportUsText')}</p>
	<a href="https://www.buymeacoffee.com/beardedbeast" target="_blank"><img src="https://cdn.buymeacoffee.com/buttons/v2/default-yellow.png" alt="Buy Me A Coffee" style="height: 60px !important;width: 217px !important;margin-bottom:40px;" ></a>
</div>

<style type="text/scss">
	.why {
		background: #fff;
		padding: 16px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		text-align: center;
		color: #000;

		& > h2 {
			font-size: 48px;
		}

		& > p {
			max-width: 800px;
			margin-bottom: 40px;
			margin-top: 0;
		}
	}
</style>