<script lang="ts">
	import Button from "./Button.svelte";
	import { _ } from 'svelte-i18n';
</script>

<div class="video">
	<h2>{$_('howItWorks', { default: 'How it works?' })}</h2>
	<div class="video-container">
		<iframe title="Ritus Preview" class="video-content" width="560" height="315" src="https://www.youtube.com/embed/F7FpKIppTRE" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
	</div>
	<Button/>
</div>

<style type="text/scss">
	.video {
		background: #000;
		padding: 16px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding-bottom: 24px;

		& > h2 {
			font-size: 48px;
			text-align: center;
		}
		
		.video-container {
    overflow: hidden;
    position: relative;
	width:100%;
	max-width: 640px;
	margin-bottom: 48px;
	
	&::after {
		padding-top: 56.25%;
		display: block;
		content: '';
	}

	& > iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
}


		color: #fff;
	}
</style>