<script>
	import { _ } from 'svelte-i18n'
  </script>

<div class="sections">
	<div><h2>01</h2><p>{$_('section01', { default: 'Create a workout routine with our application' })}</p></div>
	<div><h2>02</h2><p>{$_('section02', { default: 'Download it for free' })}</p></div>
	<div><h2>03</h2><p>{$_('section03', { default: 'Follow your workout routine' })}</p></div>
</div>

<style type="text/scss">
	.sections {
		background: black;
		display: flex;
		flex-direction: column;
		border-top: 1px solid #fff;

		@media (min-width: 640px) {
			flex-direction: row;
	}

		& > div {
			flex-basis: 33.3333%;
			display: flex;
			flex-direction: column;
			padding: 16px;

			& > h2, p {
				color: #fff;
			}

			& + div {
				border-top: 1px solid #fff;

				@media (min-width: 640px) {
					border-top: none;
					border-left: 1px solid #fff;
				}
			}
		}
	}
</style>