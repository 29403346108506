<script>
	import { _, locale } from 'svelte-i18n'
  </script>

<div class="build-options">
	<a href="https://build.ritus.fit/workout-plan?lng={$locale}" class="workout-plan-button" target="_blank">{$_('buildWorkoutPlan')}</a>
	<a href="https://build.ritus.fit/meal-plan?lng={$locale}" class="meal-plan-button" target="_blank">{$_('buildMealPlan')}</a>
</div>

<style type="text/scss">
	.build-options {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		margin-top: 40px;
		margin-bottom: 40px;
		

		@media (min-width: 640px) {
			margin-top: 0;
			flex-direction: row;
		}

		& > a {
			display: block;
			font-size: 20px;
			padding: 16px 24px;
			min-width: 240px;
			text-align: center;
			text-decoration: none;
			line-height: 20px;
			vertical-align: middle;
			border-radius: 52px;
			border: 1px solid rgba(255,255,255,0.5);
			transition: 0.25s;
			background: #000;
			
				color: #fff;

			&:hover {
				border: 1px solid #C850C0; //#FF0080
				color: #C850C0;
				transition: 0.25s;
			}

			& + a {
				margin-top: 24px;
				@media (min-width: 640px) {
					margin-top: 0;
					margin-left: 24px;
				}
			}

			
		}
	}
</style>