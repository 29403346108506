<script lang="ts">
	import Button from "./Button.svelte";
	import { _, locales,locale } from 'svelte-i18n';

	function handleClick(event) {
		event.preventDefault();

		if (history.pushState) {
			let searchParams = new URLSearchParams(window.location.search);
			searchParams.set("lng", event.target.innerHTML);
			let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + searchParams.toString();
			window.history.pushState({path: newurl}, '', newurl);
    	}
		
		locale.set(event.target.innerHTML);
		window.localStorage.setItem("i18nextLng",event.target.innerHTML);
	}
</script>
<main>
	<header><a href="https://ritus.fit" class="logo"><img alt="Ritus logo" src="../Assets/logo.svg" width="24px"/><span><strong>Ritus</strong></span></a>
		<div class="lang-switch">
			
			{#each $locales as loc}
			<a href="?lng={loc}" on:click={handleClick} value={loc}>{loc}</a>
		  {/each}
		  
		</div></header>
	
	<div class="landing-content">
		
		<h1>
			<span class="build" style={`--content:'${$_('build', { default: 'Build' })}'`}>{$_('build', { default: 'Build' })}</span>
			<span class="workout" style={`--content:'${$_('workoutPlans', { default: 'Workout Plans' })}'`}>{$_('workoutPlans', { default: 'Workout Plans' })}</span>
			<span class="meal" style={`--content:'${$_('mealPlans', { default: 'Meal Plans' })}'`}>{$_('mealPlans', { default: 'Meal Plans' })}</span>
			<!-- {$_('createWorkout', { default: 'Create a workout' })}<br/>{$_('now', { default: 'now' })} -->
		</h1>
		<Button/>
	</div>
	
</main>

<style type="text/scss">
	@-webkit-keyframes gradienttextanimation {
		0%   { opacity: 0; }
		5% {opacity: 1; }
		33% { opacity:1; }
		38% {opacity: 0;}
		100% { opacity:0; }
	}

	header {
		margin-bottom: auto;
		display: flex;
		justify-content: space-between;
		width: 100%;
		align-items: center;

		& > .logo {
			display: flex;
			align-items: center;
			color: #fff;
			text-decoration: none;

			& > img {
				margin-right: 12px;
				display: block;
			}

			& > span {
				font-size: 24px;
				line-height: 24px;
				& > strong {
					font-weight: 900;
				}
			}
		}
	}

	main {
		position: relative;
		text-align: center;
		padding: 1em;
		margin: 0 auto;
		min-height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		background: url("../Assets/streamlines.svg");
		background-color: #000000;
		background-size: cover;
		background-position: 100%;
		background-attachment: fixed;

		& > * {
			position: relative;
		}
		
	}

	h1 {
		// margin-top: -100px;
		color: #fff;
		font-size: 30px;
		font-weight: 900;
		margin-top: 0;
		display: flex;
		flex-direction: column;
		font-weight: 700;
		

		& > span {
			position: relative;
			white-space: nowrap;
			height: 48px;
			display: flex;
			align-items: center;
			justify-content: center;
			color: #fff;
			margin-bottom:8px;
			@media (min-width: 860px) {
				margin-bottom:10px;
			font-size: 100px;
			height: 130px;
		}
			@media (min-width: 1200px) {
			margin-bottom:14px;
			font-size: 140px;
			height: 160px;
		}
		
		}
		
		& > span::before {
			opacity: 0;
			position: absolute;
			white-space: nowrap;
			content: var(--content);
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
			background-color: #4158D0;
			background-image: linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%); //4158D0
			height: 50px;
			display: flex;
			align-items: center;
			justify-content: center;
			@media (min-width: 860px) {
			font-size: 100px;
			height: 130px;
		}
			@media (min-width: 1200px) {
			font-size: 140px;
			height: 180px;
		}
		
		}

		& > span.build::before {
			animation: gradienttextanimation 9s linear infinite;
		}

		& > span.workout::before {
			animation: gradienttextanimation 9s linear infinite 3s;
		}

		& > span.meal::before {
			animation: gradienttextanimation 9s linear infinite 6s;
		}

		@media (min-width: 360px) {
			font-size: 40px;
		}
		@media (min-width: 860px) {
			font-size: 100px;
		}
		@media (min-width: 1200px) {
			font-size: 140px;
		}
	}

	.lang-switch {
		display: flex;
		flex-direction: row;
		margin-bottom: auto;
		
		& > a {
			border: none;
			background: none;
			outline: none;
			color: #fff;
			text-transform: uppercase;
			position: relative;
			display: flex;
			width: 40px;
			height: 40px;
			justify-content: center;
			align-items: center;
			text-align: center;
			cursor: pointer;
			font-size: 14px;
			line-height: 14px;

			& + a::before {
				content: "";
				position: absolute;
				height: 10px;
				width: 1px;
				display: block;
				background-color: #fff;
				top: 15px;
				left: -0.5px;
			}
		}
	}

	.landing-content {
		margin-bottom: auto;
	}
</style>