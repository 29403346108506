<script lang="ts">
	import Button from "./Button.svelte";
	import { _ } from 'svelte-i18n';
</script>

<div class="why">
	<h2>{$_('aboutUsTitle')}</h2>
	<p>{@html $_('aboutUsContent1')}</p>
	<p>{$_('aboutUsContent2')}</p>
	<Button/>
</div>

<style type="text/scss">
	.why {
		background: #fff;
		padding: 16px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		text-align: center;
		color: #000;

		& > h2 {
			font-size: 48px;
		}

		& > p {
			max-width: 800px;
			margin-bottom: 40px;
			margin-top: 0;
		}
	}
</style>