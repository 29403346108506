<script>
	import { _ } from 'svelte-i18n'
  </script>

<footer>
	<a rel="noreferrer noopener" href="mailto:info@ritus.fit" title="info@ritus.fit">{$_('contactUs', { default: 'Contact us info@ritus.fit' })}</a>
	<a rel="noreferrer noopener" href="https://thedev.sk" target="_blank" title="Copyright © Ritus. All Rights Reserved. Created by TheDEV.SK">{$_('allRightReserved', { default: 'Copyright © Ritus. All Rights Reserved. Created by TheDEV.SK' })}</a>
</footer>

<style type="text/scss">
	footer {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		background: #000;
		padding: 48px 0;
		color: #fff;

		& > a {
			color: #fff;
			text-decoration: none;
			text-align: center;
			&:hover {
				text-decoration: underline;
			}
			margin-bottom: 5px;
		}
	}
</style>