<script lang="ts">
	import VideoContent from "./VideoContent.svelte";
	import MidSections from "./MidSections.svelte";
	import WhyFree from "./WhyFree.svelte";	
  import SupportUs from "./SupportUs.svelte";			
	import Header from "./Button.svelte";
	import Footer from "./Footer.svelte";
	import Main from "./Main.svelte";
    import { addMessages, init, getLocaleFromQueryString, getLocaleFromNavigator } from "svelte-i18n";

  // ...

  import en from "./Localization/en.json";
  import hu from "./Localization/hu.json";
  import sk from "./Localization/sk.json";

  addMessages("en", en);
  addMessages("hu", hu);
  addMessages("sk", sk);

  const queryLang = getLocaleFromQueryString("lng");
  if(queryLang){
    window.localStorage.setItem("i18nextLng",queryLang);
  }

  init({
	fallbackLocale: "en",
    initialLocale: getLocaleFromQueryString("lng") ?? window.localStorage.getItem("i18nextLng") ?? getLocaleFromNavigator(),
  });
</script>

<div class="content">
<Main/>
<MidSections/>
<WhyFree/>
<VideoContent/>
<SupportUs/>
<Footer/>
</div>

<style type="text/scss">
	.content {
		height:100%;
		overflow: auto;
	}
</style>
